<template>
    <iframe :src="pdfUrl" :style="{height: Height}" style="width: 100%"></iframe>
</template>

<script>
import Const from '@/core/const';

export default {
    components: {},
    props: {},
    data() {
        return {
            Height: '99vh',
            pdfUrl: ''
        };
    },
    watch: {},
    computed: {},
    created() {
        console.log('this.$route', this.$route)
        const pdf = Const.NET.FILE_URL_PREFIX + Const.UPLOAD_FILE_SUFFIX.OTHER + '/' + this.$route.query.pdf;
        // const pdf = '/Configuration.pdf'
        console.log(pdf)
        this.pdfUrl = '/pdfjs/web/viewer.html?file=' + pdf;
    },
    mounted() {},
    methods: {}
};
</script>
<style lang="scss" scoped>
</style>